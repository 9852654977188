<template>
<span>
    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Categorias</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey darken-2" dark class="mb-1" @click.prevent="atualizaDados">
                <v-icon dark>sync</v-icon>Atualizar
            </v-btn>
            <v-btn color="success" dark class="mb-1 ml-2" :to="{name: 'store-gift-list-category.add'}">
                <v-icon class="mdi mdi-plus"></v-icon>Adicionar Nova Categoria
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="storeGiftListCategories" :search="search" :rowsPerPageItems="rowsPerPageItems">

            <template v-slot:[`item.stor_glc_icone`]="{ item }">
                <td width="15%" v-if="item.stor_glc_icone"><img :src="`${base_url}upload/store_gift_list_category/i/${item.stor_glc_icone}?${uuid}`" class="avatar"></td>
                <td width="15%" v-if="!item.stor_glc_icone"><img :src="`${base_url}upload/imagem.png`" class="avatar"></td>
            </template>

            <template v-slot:[`item.stor_glc_banner`]="{ item }">
                <td width="15%" v-if="item.stor_glc_banner"><img :src="`${base_url}upload/store_gift_list_category/b/i/${item.stor_glc_banner}?${uuid}`" class="avatar"></td>
                <td width="15%" v-if="!item.stor_glc_banner"><img :src="`${base_url}upload/imagem.png`" class="avatar"></td>
            </template>

            <template v-slot:[`item.stor_glc_status`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.stor_glc_status" @change="onChangeEventHandlerStatus(item.stor_glc_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.stor_glc_show_menu`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.stor_glc_show_menu" @change="onChangeEventHandlerShowMenu(item.stor_glc_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editStoreGiftListCategory(item)" v-on="on">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="deleteStoreGiftListCategory(item)" v-on="on">
                            <v-icon dark class="mdi mdi-delete"></v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>

                <v-tooltip top color="lime black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="lime lighten-1" @click.prevent="detailStoreGiftListCategory(item)" v-on="on">
                            <v-icon dark class="mdi mdi-reorder-horizontal"></v-icon>
                        </v-btn>
                    </template>
                    <span>Detalhes</span>
                </v-tooltip>
            </template>

            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
            </template>
        </v-data-table>
    </v-card>
</span>
</template>

<script>
import {
    URL_BASE
} from "../../../config/configs";
import {
    uuid
} from "vue-uuid";

export default {
    name: "StoreGiftListCategoryComponent",
    created() {
        this.$store.dispatch("loadStoreGiftListCategories");
    },
    computed: {
        storeGiftListCategories() {
            if (this.$store.state.storegiftlistcategories.items.data == null) return [];

            return this.$store.state.storegiftlistcategories.items.data;
        }
    },
    data() {
        return {
            search: "",
            rowsPerPageItems: [
                10,
                20,
                30,
                50,
                {
                    text: "$vuetify.dataIterator.rowsPerPageAll",
                    value: -1
                }
            ],
            headers: [{
                    text: "Icone",
                    align: "left",
                    sortable: false,
                    value: "stor_glc_icone"
                },
                {
                    text: "Banner",
                    align: "left",
                    sortable: false,
                    value: "stor_glc_banner"
                },
                {
                    sortable: false,
                    text: "Nome",
                    value: "stor_glc_nome"
                },
                {
                    sortable: false,
                    text: "Código",
                    value: "stor_glc_codigo"
                },
                {
                    sortable: false,
                    text: "Ordem",
                    value: "stor_glc_ordem"
                },
                {
                    sortable: false,
                    text: "Status",
                    value: "stor_glc_status"
                },
                {
                    sortable: false,
                    text: "Mostrar menu",
                    value: "stor_glc_show_menu"
                },
                {
                    text: "Ações",
                    align: "center",
                    sortable: false,
                    value: "acoes"
                }
            ],
            storegiftlistcategory: [],
            base_url: URL_BASE,
            uuid: uuid.v1()
        };
    },
    methods: {
        atualizaDados() {
            this.$store.dispatch("loadStoreGiftListCategories");
        },
        editStoreGiftListCategory(item) {
            this.$router.push({
                name: "store-gift-list-category.edit",
                params: {
                    stor_glc_id: item.stor_glc_id
                }
            });
        },
        detailStoreGiftListCategory(item) {
            this.$router.push({
                name: "store-gift-list-category.detail",
                params: {
                    stor_glc_id: item.stor_glc_id
                }
            });
        },
        deleteStoreGiftListCategory(item, index) {
            this.$swal({
                title: "Você tem certeza?",
                text: "Você não poderá reverter isso",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#D32F2F",
                cancelButtonColor: "#1976D2",
                confirmButtonText: "Sim, Exclua!"
            }).then(result => {
                if (result.value) {
                    this.$store
                        .dispatch("destroyStoreGiftListCategory", item.stor_glc_id)
                        .then(response => {
                            if (response) {
                                this.$swal({
                                    position: "center",
                                    icon: "success",
                                    title: "Sucesso",
                                    text: "Dados excluídos com sucesso",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                                this.atualizaDados();
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "error",
                                    title: "Opssss!",
                                    text: "Erro ao excluir dados",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: "Opssss!",
                                text: "Algo errado aconteceu!",
                                showConfirmButton: true,
                                timer: 6000
                            });
                        });
                }
            });
        },
        onChangeEventHandlerStatus(stor_glc_id, value) {
            this.$store
                .dispatch("updateStoreGiftListCategoryStatus", {
                    stor_glc_id: stor_glc_id,
                    stor_glc_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Status atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar Status",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        onChangeEventHandlerShowMenu(stor_glc_id, value) {
            this.$store
                .dispatch("updateStoreGiftListCategoryShowMenu", {
                    stor_glc_id: stor_glc_id,
                    stor_glc_show_menu: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Mostrar menu atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar mostrar menu",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        }
    },
    components: {}
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}
</style>
